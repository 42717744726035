import React, { FunctionComponent } from "react"
import { format } from "date-fns"
import Image, { FluidObject } from "gatsby-image"
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image"
import powerappvideo from "../../../static/video/Onboarding.mp4"
import onboardingfront from "../../../static/images/OnboardingFront.jpg"
import { Layout } from "../../components/layout"
import { Box, Container, Grid, Paper, styled, Typography } from "@mui/material"

interface BlogPost {
  title: string
  tags: string[]
  img: any
  imgAlt?: string
  publishedDate: Date
  description: string
}

const GatsbyImageNew = styled(GatsbyImage)({
  objectFit: "cover",
  layout: "fullWidth",
  zindex: 1,
  height: "100%",
  width: "100%",
})

export const BlogPost: FunctionComponent<BlogPost> = ({
  title,
  tags,
  img,
  imgAlt,
  publishedDate,
  children,
  description,
}) => {
  return (
    <Layout title={title}>
      <Paper
        sx={{
          position: "relative",
          backgroundColor: "grey.800",
          color: "#fff",
          mb: 4,
          height: "55vh",
          overflow: "hidden",
          borderRadius: 0,
        }}
      >
        <GatsbyImageNew image={img} alt={imgAlt || title} />
        <Grid
          container
          sx={{
            inset: 0,
            backgroundColor: "rgba(0,0,0, .7)",
            position: "absolute",
            zIndex: 2,
            display: "flex",
            alignItems: "center",
          }}
        >
          <Container>
            <Grid item>
              <Box>
                <Typography
                  component="h1"
                  variant="h3"
                  color="inherit"
                  gutterBottom
                >
                  {title}
                </Typography>
                <Typography
                  variant="h5"
                  color="inherit"
                  paragraph
                  sx={{ display: "flex" }}
                >
                  {description}
                </Typography>
              </Box>
            </Grid>
          </Container>
        </Grid>
      </Paper>
      <Container>
        <Typography component={"div"} variant={"body1"}>
          {children}
          <b>Power Platform Multistage approval Example:</b>
          <br />
          <video controls width="620" poster={onboardingfront}>
            <source src={powerappvideo} type="video/mp4" />
          </video>
        </Typography>
      </Container>
    </Layout>
  )
}
