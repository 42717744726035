import React, { FunctionComponent } from "react"
import { graphql } from "gatsby"
import { BlogPost } from "../../components/blogPost"
import { SEO } from "../../components/seo/"
import { getImage, getSrc, IGatsbyImageData } from "gatsby-plugin-image"
interface QueryData {
  markdownRemark: {
    html: string
    frontmatter: {
      title: string
      tags: string[]
      img: {
        childImageSharp: {
          gatsbyImageData: IGatsbyImageData
        }
      }
      imgAlt: string
      publishedDate: string
      description: string
    }
  }
}

export const pageQuery = graphql`
  query BlogPostByID($id: String!, $tag: [String!]) {
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      fields {
        slug
      }
      frontmatter {
        title
        description
        tags
        img {
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        imgAlt
        publishedDate
      }
    }
    allMarkdownRemark(
      limit: 3
      sort: { fields: [frontmatter___publishedDate], order: DESC }
      filter: { frontmatter: { tags: { in: $tag } }, id: { ne: $id } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            description
            tags
            img {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
    }
  }
`

interface Page {
  data: QueryData
}

export const Page: FunctionComponent<Page> = ({ data }) => {
  const {
    markdownRemark: {
      html,
      frontmatter: { title, tags, imgAlt, description, img, publishedDate },
    },
  } = data

  const blogImage = getImage(img.childImageSharp.gatsbyImageData)
  const src = getSrc(img.childImageSharp.gatsbyImageData)
  return (
    <>
      <SEO title={title} description={description} image={src} />
      <BlogPost
        title={title}
        tags={tags}
        img={blogImage}
        imgAlt={imgAlt}
        publishedDate={new Date(publishedDate)}
        description={description}
      >
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </BlogPost>
    </>
  )
}

export default Page
